@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?so6xrp');
  src:  url('./icomoon.eot?so6xrp#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?so6xrp') format('truetype'),
    url('./icomoon.woff?so6xrp') format('woff'),
    url('./icomoon.svg?so6xrp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e900";
}
.icon-X:before {
  content: "\e901";
}
.icon-bell-header:before {
  content: "\e950";
}
.icon-calendar-general:before {
  content: "\e97b";
}
.icon-calendar-notification:before {
  content: "\e97c";
}
.icon-clock-general:before {
  content: "\e97d";
}
.icon-clock-notification:before {
  content: "\e97e";
}
.icon-oval-general:before {
  content: "\e97f";
}
.icon-plus-general:before {
  content: "\e980";
}
.icon-profile-general:before {
  content: "\e981";
}
.icon-profile-header:before {
  content: "\e982";
}
.icon-profile-notification:before {
  content: "\e983";
}
.icon-radius-general:before {
  content: "\e984";
}
.icon-store-general:before {
  content: "\e985";
}
.icon-store-notification:before {
  content: "\e986";
}
.icon-store-location-general:before {
  content: "\e987";
}
.icon-radius-general-radius:before {
  content: "\e984";
  color: #d4d4d4;
}
