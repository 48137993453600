body {
  background : white !important;
  color      : #1d1d1d;
  font-family: Roboto;
  overflow: hidden;
}

.callback-wrapper {
  display        : flex;
  height         : 100vh;
  justify-content: center;
  align-items    : center;
}

.shift-wrapper:focus {
  background-color: #22cca5;
}

.ant-btn {
  border-radius: 8px !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.only-h-100 {
  height: 100%;
}

.layout {
  position        : absolute;
  top             : 0;
  bottom          : 0;
  right           : 0;
  left            : 0;
  background-color: white;

  // STYLING FOR SMALL SCREEN START
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 8px;
  border: 0.5px solid #eee;
  // STYLING FOR SMALL SCREEN END

  .ant-layout-footer {
    background-color  : white;
    padding           : 18px 10px;
    -webkit-box-shadow: -1px -4px 30px -6px rgba(0, 0, 0, 0.53);
    -moz-box-shadow   : -1px -4px 30px -6px rgba(0, 0, 0, 0.53);
    box-shadow        : -1px -4px 30px -6px rgba(0, 0, 0, 0.53);

    .icon {
      font-size: 20px;
    }

    .title {
      font-size: 10px;
    }

    a {
      color: #ccc;

      &.active {
        color: black;
      }
    }
  }
}

.p-1 {
  padding: 0.5rem;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.p-2 {
  padding: 1rem;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left : 0.5rem !important;
}

.py-1 {
  padding-top   : 0.5rem;
  padding-bottom: 0.6rem;
}

.my-1 {
  margin-top   : 1rem;
  margin-bottom: 1rem;
}

.header {
  width  : 100%;
  padding: 10px;
  margin-top: -1px;
}

.title-text {
  font-size  : 25px;
  font-weight: 600;
}

.big-text {
  font-size: 16px;
}

.mini-text {
  font-size: 14px;
}

.micro-text {
  font-size: 12px;
}

.bold {
  font-weight: bold;
}

.d-flex {
  display: flex;
}

.col-center {
  display        : flex;
  justify-content: center;
  flex-direction : column;
  align-items    : center;
}

.full-page {
  height        : 100%;
  display       : flex;
  flex-direction: column;

  .full-height {
    flex      : 1 1 auto;
    overflow-y: auto;
  }
}

.b-none {
  border: none !important;
}

.grey-text {
  color: #9D9D9D;
}

.space-between {
  justify-content: space-between;
}

.custom-form {
  width         : 100%;
  color         : #1d1d1d !important;
  display       : flex;
  flex-direction: column;

  .bottom-row {
    margin-top: auto;
  }

  input {
    color      : #1d1d1d;
    font-family: 500;
  }

  .item-wrapper {
    display: flex;
    padding: 1rem 0;
    flex-shrink: 0
  }

  .ant-form-item {
    margin-bottom: 0;
    min-width: 180px;
    .ant-picker-range .ant-picker-clear {
      right: 0;
    }
  }

  .ant-form-item-explain {
    padding: 0 0.5rem;
  }

  .date-picker {
    width  : 100%;
    padding: 0 0.5rem;

    input {
      font-size: 17px;
    }
  }

  .label-text {
    font-size: 11px;
    color    : #C1C1C1;
    padding  : 0 0.75rem;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    font-size    : 36px;
    align-self   : center;
    padding-right: 0.5rem;
  }
}

/************************* SWITCH CSS ******************/

.switch {
  display : inline-block;
  position: relative;
  width   : 50px;
  height  : 30px;
}

.switch input {
  opacity: 0;
  width  : 0;
  height : 0;
}

.slider {
  position          : absolute;
  cursor            : pointer;
  top               : 0;
  left              : 0;
  right             : 0;
  bottom            : 0;
  background-color  : #ccc;
  -webkit-transition: 0.4s;
  transition        : 0.4s;
}

.slider:before {
  position        : absolute;
  bottom          : 3px;
  left            : 4px;
  transition      : 0.4s;
  background-color: white;
  width           : 24px;
  height          : 24px;
  content         : "";
}

input:checked+.slider {
  background-color: @primary-color;
}

input:checked+.slider:before {
  left: -1px;
}

input:focus+.slider {
  box-shadow: 0 0 1px @primary-color;
}

input:disabled+.slider {
  opacity: 0.5;
}

input:checked+.slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform    : translateX(23px);
  transform        : translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 44px;
}

.slider.round:before {
  border-radius: 50%;
}

/************************* SWITCH CSS END ******************/

.footer-button {
  position: relative;

  .count {
    color        : white;
    position     : absolute;
    top          : -12px;
    right        : 8px;
    border-radius: 29px;
    background   : #22CCA5;
    padding      : 2px 8px;
    font-size    : 12px;
  }
}

.pending-border {
  border-color: #B463F0 !important;
}
.claimed-border {
  border-color: #22CCA5 !important;
}
.cancelled-border {
  border-color: #FF4D4F !important;
}

.pending-background {
  background-color: #B463F0 !important;
}
.claimed-background {
  background-color: #22CCA5 !important;
}
.cancelled-background {
  background-color: #FF4D4F !important;
}

.hide-number-arrows .ant-input-number-handler-wrap{
  display: none;
}

.add-shift-datepicker{
  min-width: 200px!important;
}

.font-weight-800{
  font-weight: 800;
}

.justify-content-space-around{
  justify-content: space-around;
}
