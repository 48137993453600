.p-0 {
  padding: 0;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.height-75{
  height: 75px;
}
.height-200{
  height: 200px;
}
.footer-container{
  padding: 0 !important;
}
.footer-logo-img{
  width: 100%;
  height: 100%; 
  padding: 20px;
}

.ant-select, .ant-select-item{
  font-size: 16px !important;
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0px;
}

.ant-btn > .ant-btn-loading-icon {
  padding-right: 8px;
}

.react-timekeeper {
  position: absolute  !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999
}